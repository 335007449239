import axios from "axios"
import { useEffect, useRef, useState } from "react";

export default function ProductList() {

    let [data,setData] = useState([])
    let [clients,setClients] = useState([])
    let [products,setProducts] = useState([])

    let selHospRef = useRef();
    let selDevRef = useRef();

    
    const getProductList = ()=>{
        async function fetchData() {
            try {
                const token = localStorage.getItem('access-token');
                
                const response = await axios.get(process.env.REACT_APP_BACKEND_URL+ '/api/product', {
                    headers: { 'access-token': token }
                });
    
                
    
                const c = new Set(response.data.map(v=>v.client_name));
    
                console.log(response.data);
                console.log('c',c);
                
                setClients([...c]);
                setData(response.data);
            
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }

    const hospitalChange = e=>{
        const selected = selHospRef.current.value;
        const pds = data.filter(v=> v.client_name === selected)
        selDevRef.current.selectedIndex = 0;
        setProducts(pds);
    }
    const handleChange = e=>{
        console.log(selHospRef.current.value);
    }

    useEffect(getProductList,[])

    return (
        <article>
            <header>
                <h1>Product List</h1>
            </header>
            <main>
                <label>Hospital
                <select ref={selHospRef} onChange={hospitalChange}>
                    <option selected disabled value="">Select Hospital</option>
                    {clients.map(v=>(<option>{v}</option>))}
                </select>
                </label>

                <label>Device
                <select ref={selDevRef} onChange={handleChange}>
                    <option selected disabled value="">Select Device</option>
                    {products.map(v=>(<option> {v.pname} / {v.serialno}</option>))}
                </select>
                </label>

            </main>
        </article>
    
)
}