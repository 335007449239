import './App.css';

import React, {useState} from 'react';
import {BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Navigation from './comp/Navigation';
import HelloWorld from './HelloWorld';
import Login from './comp/Login';
import Manage from './comp/Manage';
//import Upload from './comp/Upload';
import UploadTest from './comp/UploadTest';
import Analysis from './comp/Analysis';
import AnalysisS3 from './comp/AnalysisS3';
import ProductList from './product/ProductList'
import ReqProgressComponent from './comp/ReqProgressComponent'
import AddUser from './comp/admin/AddUser';
import ArchViewByOp from './comp/ArchViewByOp';
import DoctorRecord from './comp/DoctorRecord';


//console.log('App.js backend: ' + process.env.REACT_APP_BACKEND_URL);

function App() {

  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);

  const props = {
    token: token,
    setToken:setToken,
    userInfo:{
      user: user,
      setUser: setUser
    }
  };

  // useEffect(()=>{
  //     console.log("useEffect: ", user?.username);
  // },[user]);
  
  return (
    <BrowserRouter>
      <header>
          <Navigation props={props}/>
      </header>
      <main>
        <Routes>
          <Route path="/" element={user?.username ? (<Navigate to="/dashboard" />):(<Navigate to="/login" />)} />
          <Route path="/login" element={user?.username ? <Navigate to="/dashboard" /> : <Login props={props} setUser={setUser}/>} />
          <Route path="/upload" element={<UploadTest />} />
          <Route path="/manage/:page" element={<Manage />} />
          <Route path="/manage/" element={<Manage />} />
          <Route path="/dashboard" element={<HelloWorld />} />
          <Route path="/analysis" element={<Analysis />} />
          <Route path="/analysis_k" element={<AnalysisS3 />} />
          <Route path="/product" element={<ProductList />} />
          <Route path="/req/:fkey" element={<ReqProgressComponent />}/>
          <Route path="/archviewop/:fkey" element={<ArchViewByOp />}/>
          <Route path="/admin/adduser" element={<AddUser />} />
          <Route path="/oprecord" element={<DoctorRecord />} />
        </Routes>
      </main>
    </BrowserRouter>
  );
}

export default App;
