import { useRef, useState } from "react";

function InputState({text, type,state, placeholder}){
    return (
    <div>
        <b>{text}</b>
        <input type={type} value={state[0]} onChange={(e)=>{state[1](e.target.value)}} placeholder={placeholder} required />
    </div>
    )
}
export default function AddUser() {

    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [userType, setUserType] = useState('');
    
    const [status, setStatus] = useState('-');
    
    const requestToBackend = (postData) =>{
        try {
            const token = localStorage.getItem('access-token');
            fetch('/api/admin/adduser',{
                method: 'POST',
                body: JSON.stringify(postData),
                credentials: 'include',
                headers: { 
                    'Content-Type': 'application/json',
                    'access-token': token
                }
            }).then(res => {
                if(res.ok) {
                    console.log(res);
                    setStatus('Adding user success');
                }
            });
        } catch(err) {
            console.log(err);
            setStatus(err);
        }
    }

    const onAddUserSubmit = async (e) => {
        e.preventDefault();

        setStatus('유저 추가 요청 중');

        requestToBackend({
            user:{
                userid:id,
                username:name,
                password:password,
                userType:userType
            }
        })
      }

    return (
        <article>
            <header>
                <h1>사용자 추가</h1>
            </header>
            <main>
                <form onSubmit={onAddUserSubmit}>
                    <InputState type="text" text="userid:" state={[id, setId]} placeholder="아이디를 입력하세요." />
                    <InputState type="text" text="이름:" state={[name, setName]} placeholder="표시할 이름을 입력하세요."/>
                    <InputState type="password" text="Password:" state={[password, setPassword]} placeholder="패스워드를 입력하세요."/>
                    <InputState type="password" text="Confirm Password:" state={[confirmPassword, setConfirmPassword]} placeholder="패스워드를 한번 더 입력하세요."/>
                    <InputState type="text" text="User Type (admin/user/upload/doctor):" state={[userType, setUserType]} placeholder="유저 유형을 입력하세요"/>
                    
                    <input type="submit" value="추가" />
                </form>
                <hr />
                {status}

            </main>

        </article>
    )
}