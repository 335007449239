import { Link } from "react-router-dom";
import User from './User';

export default function Navigation({ props }) {
  const user = props.userInfo.user;

  // console.log(props.userInfo);
  return (
    <nav>
      <ul>
        <li><h2><Link to="/"><strong>Zamenix Archiving System</strong></Link></h2></li>
      </ul>
      <ul>
        {user ? (
            <>
              {['admin','upload'].includes(user.userType) && (<li><Link to="upload">업로드</Link></li>) }
              {['admin','upload'].includes(user.userType) && (<li><Link to="manage">기록 관리</Link></li>) }
              {['doctor'].includes(user.userType) && (<li><Link to ="oprecord">수술 기록</Link></li>)}
              {['admin'].includes(user.userType) && (<li><Link to="admin/adduser">AddUser</Link></li>)}
            </>
        ) : ("")
        }
        <li><User props={props} /></li>
      </ul>
    </nav>
  );
}
