import { useState, useRef } from "react";
import axios from 'axios';
import JSZip from "jszip";


const API_URL = process.env.REACT_APP_BACKEND_URL;
const uploadURL = API_URL + '/api/upload';
const presignedURL = API_URL + '/api/generate-presigned-url';

async function getFileDescriptions(file, setters) {
    try {
        // ZIP 파일 읽기
        const zip = new JSZip();
        const zipContent = await zip.loadAsync(file);
        
        // readme.txt 파일 찾기
        const readmeFile = zipContent.file("devserial.txt");
        
        if (readmeFile) {
          // readme.txt 파일의 내용을 텍스트로 읽기
          const content = await readmeFile.async("text");
          
          // 첫 번째와 두 번째 줄만 추출
          const lines = content.split('\n');
          //const firstTwoLines = lines.slice(0, 2).join('\n');
          
          // 결과 설정
          if(lines.length >= 3){
            setters.deviceSerial(lines[0]);
            setters.hospitalName(lines[1]);
            setters.deviceNote(lines[2])
          }
          setters.message("기기 정보 확인 완료");
        } else {
            setters.message("기기 정보를 찾을 수 없습니다.");
        }
      } catch (error) {
        console.error("파일을 처리하는 중 오류가 발생했습니다.", error);
        setters.message("파일 처리 중 오류가 발생했습니다.");
      }
}

export default function UploadTest(){
    const fileRef = useRef(null);
    const hospNameRef = useRef(null);
    const memoRef = useRef(null);
    const devSerialRef = useRef(null);

    const [progress , setProgress] = useState(0);
    const [total, setTotal] = useState([0,1,0]);
    const [uploadEnabled, setUploadEnabled] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [file, setFile] = useState(null);

    const [zipresult, setZipResult] = useState('파일을 선택하세요.');

    
    const setDeviceSerial = (v)=>{ devSerialRef.current.value = v; }
    const setHospitalName = (v)=>{ hospNameRef.current.value = v;  }
    const setDeviceNote = (v)=> { memoRef.current.value = v;}

    const handleFileInput = (e) => {        
        if(fileRef.current.files.length > 0) {
            const file = fileRef.current.files[0];

            getFileDescriptions(file,{
                message:setZipResult, 
                deviceSerial:setDeviceSerial, 
                hospitalName:setHospitalName,
                deviceNote:setDeviceNote
            });

            setFile(file);
            setProgress(0);
            setUploadEnabled(true);
        }
    }

    
    const insertDB = (dbData) =>{
        try {
            const token = localStorage.getItem('access-token');
            fetch(uploadURL,{
                method: 'POST',
                body: JSON.stringify(dbData),
                credentials: 'include',
                headers: { 
                    'Content-Type': 'application/json',
                    'access-token': token
                }
                

            }).then(res => {
                if(res.ok) {
                    setUploading(false);
                    setUploadEnabled(true);
                }
            });
        } catch(err) {
            console.log(err);
        }
    }

    
    const uploadFile = async (e) => {
        e.preventDefault();


        setUploading(true);
        setUploadEnabled(false);

        const startTime = Date.now();

        try {
            const fileInfo = {
                fileName: file.name,
                fileType: file.type
            };
            const header = {
                headers: { 
                    'access-token': localStorage.getItem('access-token')
                }
            }

            const { data } = await axios.post(presignedURL, fileInfo, header);
    
            const options = {
                headers: {
                    'Content-Type': file.type
                },
                onUploadProgress: (progressEvent) => {
                    const currTime = Date.now();
                    
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    const uploadSpeedBytePerSecond = progressEvent.loaded / ((currTime-startTime) / 1000);

                    setTotal([progressEvent.loaded, uploadSpeedBytePerSecond]);

                    setProgress(percentCompleted);
                }
            };
    
            await axios.put(data.url, file, options).then(()=>{
                setProgress(100);
            });

            
            insertDB({
                fileKey: data.fileKey,
                fileName: file.name,
                hospitalName: hospNameRef.current.value,
                devSerial: devSerialRef.current.value,
                desc: memoRef.current.value,
            });

        } catch (err) {
            console.error('Error uploading file:', err);
        } finally {
            // setUploading(false);
            // setUploadProgress(0);
            // setFile(null);
        }
      }

      
    return (
        <article>
            <header>
                <h1>Data Upload</h1>
            </header>
            <main>
                <form onSubmit={uploadFile}>
                    <input type="file" ref={fileRef} onChange={handleFileInput} disabled={uploading}/>
                    {zipresult}<hr/>
                    <b>장비 식별기호:</b>
                    <input type="text" ref={devSerialRef} placeholder="장비 식별기호를 입력하세요." disabled={uploading}/>
                    <b>병원 이름:</b>
                    <input type="text" ref={hospNameRef} placeholder="병원 이름을 입력하세요." disabled={uploading}/>
                    <b>메모:</b>
                    <textarea ref={memoRef} placeholder="기타 메모 사항을 입력하세요." disabled={uploading}></textarea>
                    <input type="submit" value="Upload Data" disabled={!uploadEnabled || uploading} />
                </form>
                <hr />
                <div>
                    <div color="primary">업로드 진행률 : {progress}% ({(total[1]/(1024*1024)).toFixed(2)} MB/s)</div>
                    <div> <progress value={progress} max="100" /> </div>
                </div>
                

            </main>

        </article>

      );
}