import React from 'react';

const HelloWorld = () => {
  return (
    <article>
      <header>
        <h1>Dashboard</h1>
      </header>
      <main>
        <h2>Contents</h2>
      </main>
    </article>
  );
};

export default HelloWorld;
