import { useEffect, useRef, useState } from "react";

const table = {
    'HardStone':{
        'Fine Dusting':{
            'TFL':{
                '150':35.68,
                '272':45.93
            },
            'Ho':{
                '272':null
            }
        },
        'Dusting':{
            'TFL':{
                '150':38.38,
                '272':61.1
            },
            'Ho':{
                '272':16.26
            }
        },
        'Fragmentation':{
            'TFL':{
                '150':41.21,
                '272':66.96
            },
            'Ho':{
                '272':31.74
            }
        }
    },
    'SoftStone':{
        'FineDusting':{
            'TFL':{
                '150':37.77,
                '272':44.88
            },
            'Ho':{
                '272':null
            }
        },
        'Dusting':{
            'TFL':{
                '150':41.12,
                '272':62.88
            },
            'Ho':{
                '272':22.99
            }
        },
        'Fragmentation':{
            'TFL':{
                '150':50.16,
                '272':66.57
            },
            'Ho':{
                '272':38.6
            }
        },
    }
}

function InputBox({text,state=["",e=>{}]}) {

    const handleChange = (ev)=>{
        state[1](ev.target.value)
    }

    return (
    <label style={{display:"block"}}>
        <strong>{text}</strong>
        <input type="text" defaultValue={state[0]} onChange={handleChange}/>
    </label>
    )
}
function SelectBox({text,options,state=["",e=>("")]}) {

    const handleChange = (e)=>{
        state[1](e.target.value)
    }
    return (
        <fieldset>
            <legend><strong>{text}</strong></legend>
            {
                options.map(v =>
                (
                    <>
                    <input 
                        type="radio" 
                        key={v} 
                        id={v}
                        value={v} 
                        checked={state[0] === v}
                        onChange={handleChange} 
                    />
                    <label htmlFor={v}>{v}</label>
                    </>
                    
                ))
            }
            
            
        </fieldset>
    )
}

function SelectWithInputBox({text,options,state=["",e=>("")]}) {

    let customInputRef = useRef(null);
    let customRadioRef = useRef(null);

    const inputChange = (e)=>{
        state[1](e.target.value)
    }
    const radioChange = (e)=>{
        state[1](e.target.value)

        customRadioRef.current.checked = false;
        customInputRef.current.disabled = true;
    }
    const customInputRadioSelect = (e)=>{
        console.log(e);
        console.log(customInputRef);

        state[1](0)
        customInputRef.current.disabled = false;
        customInputRef.current.value="";
        customInputRef.current.focus();
    }

    return (
        <fieldset>
            <legend>{text}</legend>
            
            {
                options.map(v =>
                (
                    <label>
                    <input 
                        type="radio" 
                        key={v} 
                        value={v} 
                        checked={state[0] === v && customRadioRef.current?.checked !== true}
                        onChange={radioChange} 
                    />
                    {v}
                    </label>
                    
                    
                ))
            }

            <label>
                <input type="radio" key="custom" ref={customRadioRef} onChange={customInputRadioSelect} />
                user input: 
                <input type="text" ref={customInputRef} onChange={inputChange} />
            </label>
        </fieldset>
    )
}


const articleStyle = {
    maxHeight:"500px",
    boxShadow: "5px 10px 10px 1px rgba(0,0,0,.5)",
    overflow:"auto"
}

export default function InputOpParams({onClose, onOK}) {
    const [waitButton, setWaitButton] = useState(false);

    let stone ={
        volume: useState(760),
        maxDiameter: useState(12),
        count: useState(1),
        type: useState("수산칼슘"),
        position: useState("Upper Pole"),
        density: useState(1020),
    }
    let laser ={
        type: useState("Ho"),
        opType: useState("Dusting"),
        wavelength: useState("272"),
        power: useState(1),
        frequency: useState(13)
    }
    let allRef ={
        stone,
        laser,
        surgeonExpertise: useState("Expertise"),
        //laserTimeMin: data.laserTimeMin
    }

    const handleOK = ()=>{
        const data = {
            stone:{
                volume: stone.volume[0],
                maxDiameter: stone.maxDiameter[0],
                count: stone.count[0],
                type: stone.type[0],
                position: stone.position[0],
                density: stone.density[0]
            },
            laser:{
                type: laser.type[0],
                opType: laser.opType[0],
                wavelength: laser.wavelength[0],
                power: laser.power[0],
                frequency: laser.frequency[0]
            },
            surgeonExpertise: allRef.surgeonExpertise[0]
        };
        setWaitButton(true);
        
        onOK(data);
        
        setTimeout(()=>{
            setWaitButton(false);
        },1000);
        
    };

    return (
        <article sytle={articleStyle}>
            <header>
                <h2>수술 환경 입력 </h2>
            </header>
            <main>
                <InputBox text="결석 부피(㎣): " state={stone.volume}/>
                <InputBox text="결정 장경(㎜): " state={stone.maxDiameter}/>
                <InputBox text="결석 수(개): " state={stone.count}/>
                <SelectBox text="결석 위치: " options={["Upper Pole","Middle Pole","Lower Pole"]} state={stone.position} />
                <InputBox text="결석 경도(HU): " state={stone.density}/>    

                <SelectWithInputBox text="레이저 파이버 직경(㎛): " options={["150","272"]} state={laser.wavelength}/>
                <SelectBox text="레이저 종류: " options={["Ho","TFL"]} state={laser.type}/>
                <InputBox text="레이저 출력(J): " state={laser.power}/>
                <InputBox text="레이저 출력빈도(Hz): " state={laser.frequency}/>
                <SelectBox text="레이징 종류: " options={["Fine Dusting","Dusting","Fragmentation"]} state={laser.opType}/>
                <SelectBox text="수술자 숙련도: " options={["Expertise","Intermidate","Novice"]}state={allRef.surgeonExpertise}/>
            </main>
            <footer style={{position:'sticky',bottom:0,textAlign:'right'}}>
                <button disabled={waitButton} style={{width:'15%'}}onClick={onClose} class='secondary'>Close</button>&nbsp;
                <button aria-busy={waitButton} style={{width:'25%'}}onClick={handleOK}>{waitButton && 'Wait...' || 'Save'}</button>
            </footer>
        </article>


    )
}