import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import zoomPlugin from 'chartjs-plugin-zoom';

function ChartItem({selectedItem, setSelectedItem, columnNames, label = "Item"}) {
    
    const handleSelectChange = (event) => {
        setSelectedItem(event.target.value);
    };

    return (
    <div>
        <label>{label}</label>
        <select value={selectedItem} onChange={handleSelectChange}>
            <option value="">-- Select an item --</option>
            {columnNames.map((name, index) => (
                <option key={index} value={name}>{name}</option>
            ))}
        </select>
    </div>
    );
}


function optimizeData(data, key) {
    if (data.length == 0) return [];

    const optimizedData = [data[0]]; // Start with the first data point

    let last = 0;

    const isDataChanged = (old,cur) => {
        if(isNaN(old) || isNaN(cur))
            return old != cur;

        return Math.abs(cur-old) > Math.abs(cur * 0.003);
    };

    for (let i = 1; i < data.length; i++) {
        //if (data[i][key] !== data[i - 1][key]) {
        if ( isDataChanged(data[i][key],data[last][key])) {
            if(last != i-1)
                optimizedData.push(data[i-1]);
            optimizedData.push(data[i]); // Add only when the value changes
            last = i;
        }
    }

    if(last < data.length-1)
        optimizedData.push(data[data.length-1]);

    // if(opt == true)
    //     optimizedData.push(data[-1])

    return optimizedData;
}

const extractChartData = (data, item) => {
    const optimizedData = optimizeData(data, item);

    const ret = optimizedData.map(entry => ({x:entry.datetime, y:parseFloat(entry[item]) || 0}));
    // const times = optimizedData.map(entry => entry.datetime);
    // const values = optimizedData.map(entry => parseFloat(entry[item]) || 0);
    
    return ret;
}

export default function LogChart2({logData}){
    const data = logData;
    
    const [selectedItem, setSelectedItem] = useState('');
    const [selectedItem2, setSelectedItem2] = useState('');
    const [chartData, setChartData] = useState({});

    
    const columnNames = data.length > 0 ? Object.keys(data[0]).filter(key => key !== 'datetime' && key !== 'id' && key !== 'controller' && key !== 'type') : [];


    const options = {
        scales: {
            x: {
                type: 'time',  // Set the x-axis type to 'time'
                time: {
                    unit: 'second',  // Adjust the time unit as needed (e.g., 'minute', 'hour', 'day')
                    tooltipFormat: 'yyyy-MM-dd HH:mm:ss.SSS',  // Format for tooltips
                },
                title: {
                    display: true,
                    text: 'Time'
                }
            },
        },

        plugins: {
            tooltip: {
                intersect: false,   // Tooltip will appear even when not directly over a point
                mode: 'index'       // Tooltip shows the data for the nearest x-axis point
            }, 
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'x',  // Enable panning on the x-axis
                },
                zoom: {
                    wheel: {
                        enabled: true,  // Enable zooming with the mouse wheel
                    },
                    pinch: {
                        enabled: true  // Enable zooming with pinch gestures
                    },
                    mode: 'x',  // Enable zooming on the x-axis
                }
            }
        }
    };

    
const generateChartData = () => {
    if (selectedItem && data.length > 0) {
        // const optimizedData = optimizeData(data, selectedItem);

        const times = data.map(entry => entry.datetime);
        //const values = optimizedData.map(entry => parseFloat(entry[selectedItem]) || 0);

        let values = extractChartData(data,selectedItem);


        let datasets = [];

        datasets.push({
            label: selectedItem + `(${values.length}/${data.length})`,
            data: values,
            fill: false,
            borderColor: 'rgba(75,192,192,1)',
            tension: 0.1,
            pointRadius: 0,
            borderWidth: 1
        });

        if(selectedItem2 ) {
            let values2 = extractChartData(data,selectedItem2);
            //console.log(times2,values2);
            datasets.push({
                label: selectedItem2 + `(${values2.length}/${data.length})`,
                data: values2,
                fill: false,
                borderColor: 'rgba(192,75,75,1)',
                tension: 0.1,
                pointRadius: 0,
                borderWidth: 1
            })
        }



        setChartData({
            labels: times,
            datasets: datasets
        });

        // Scroll to the bottom of the page
        setTimeout(() => {
            window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
        }, 100);  // Delay to ensure chart is rendered
    }
};

    return (
        <>
            <div style={{float:"left", paddingRight:"10px",marginRight:"10px", width:"400px",borderRight:"1px solid rgba(100,100,100,0.3)"}}>
                {data.length > 0 && 
                    (<ChartItem label="Item 1" selectedItem={selectedItem} setSelectedItem={setSelectedItem} columnNames={columnNames} />)
                }
                {selectedItem != "" && 
                    (<ChartItem label="Item 2" selectedItem={selectedItem2} setSelectedItem={setSelectedItem2} columnNames={columnNames} />)
                }
                {data.length > 0 && (<button onClick={generateChartData}>Generate Chart</button>)}
            </div>        
        
        {selectedItem && chartData.labels && (
            <div style={{
                display: "flex",
                justifyContent: "center",
                //border: "1px solid red",
                alignItems: "center",
                // height: "35vh",
                minHeight:"400px",
                padding: "0 30px",
            }}>
                <Line data={chartData} options={options} plugins={[zoomPlugin]} />
            </div>
        )}
        </>
    )
}