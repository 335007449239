import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

//console.log("backend: " + process.env.REACT_APP_BACKEND_URL);

const Login = ({ setUser }) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    const navigate = useNavigate();


    const fetchUser = async () => {
        try {
            const token = localStorage.getItem('access-token');
            if (token) {
                const response = await axios.get(process.env.REACT_APP_BACKEND_URL+ '/api/user', {
                    headers: { 'access-token': token }
                });
                //console.log("set User" , response.data);
                setUser(response.data);
            }
        } catch (error) {
            setUser(null);
            navigate("/login");
        }
    };

    const handleLogin = async (e) => {
      e.preventDefault();
        try {
            //console.log (process.env.REACT_APP_BACKEND_URL + "/api/login");

            const response = await axios.post(
                process.env.REACT_APP_BACKEND_URL + "/api/login",
                {
                    username,
                    password,
                },
                { withCredentials: true }
            );
            //console.log('login success, token: ', response.data.token);
            localStorage.setItem('access-token', response.data.token);
            setMessage(response.data.message);
            //setUser(response.data.user);
            fetchUser();
            navigate('/');
        } catch (error) {
            console.log(error);
            setMessage(error.response.data.message);
        }
    };

    return (
        <article>
            <header>
                <h1>Login</h1>
            </header>
            <main>
            <form onSubmit={handleLogin}>
                <fieldset>
                    <label>
                        Username
                        <input
                            type="text"
                            placeholder="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </label>
                    <label>
                        Password
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            autoComplete="off"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </label>
                </fieldset>
                <input type="submit" value="Login" />
            </form>
            </main>
            <footer>
                <p>{message}</p>
            </footer>
            
        </article>
    );
};

export default Login;
