// src/User.js
import React, { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const User = ({props}) => {
    const user = props.userInfo.user;
    const setUser = props.userInfo.setUser;
    
    //console.log("User.js / ", user);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUser = async () => {
            // console.log('user verification: ', user)
            try {
                const token = localStorage.getItem('access-token');
                if (token) {
                    const response = await axios.get(process.env.REACT_APP_BACKEND_URL+ '/api/user', {
                        headers: { 'access-token': token }
                    });
                    // console.log("set User" , response.data);
                    setUser(response.data);
                } else {
                    navigate("/login");
                }
            } catch (error) {
                console.log(error);
                setUser(null);
                navigate("/login");
            }
        };
        fetchUser();
    }, []);

    if(user) {
        return (
            <article>
                <div className="grid" style={{width:"250px"}}>
                    <div><small>Hello, </small><b>{user.username}</b></div>
                    <button onClick={async () => {
                        await axios.post(process.env.REACT_APP_BACKEND_URL+'/api/logout', {}, { withCredentials: true });
                        localStorage.removeItem('access-token');
                        setUser(null);
                        navigate("/");
                    }}>Logout</button>
                </div>
            </article>
        );
    }

    return ( <button onClick={()=>{navigate("/login")}}>Log In</button> )
};

export default User;