import { useNavigate, useParams } from "react-router-dom";
import UploadItem from "./UploadItem";
import { useEffect, useState } from "react";
import ReqProgressComponent from "./ReqProgressComponent"

const API_URL = process.env.REACT_APP_BACKEND_URL;
const datalistURL = API_URL + '/api/datalist';


function PopupAnalysis({visible, onClose, fileKey}) {
    const popupStyle= {
        position:"fixed",
        top:0,
        left:0,
        right:0,
        bottom:0,
        display: visible ? "block" : "none",
        padding: "25px",
        backgroundColor: "rgba(30,30,30,0.5)",
        boxShadow: "5px 10px 10px 1px rgba(0,0,0,.5)"
    }

    const articleStyle = {
        maxHeight:"500px",
        boxShadow: "5px 10px 10px 1px rgba(0,0,0,.5)",
        overflow:"auto"
    }

    return (
      <div style={popupStyle}>
        <article sytle={articleStyle}>
          <header><button onClick={onClose}>[X]</button>Analysis</header>
          <main>
            <ReqProgressComponent fileKey={fileKey}/>
          </main>
        </article>
      </div>
    )
}

export default function Manage(props){
    const navigate = useNavigate();
    let {page} = useParams();
    
    let page2 = 0;

    if(page == null)
        page = 0;

    if(isNaN(page) || page < 0)
        page2 = 0;
    else page2 = page;

    const [data, setData] = useState([]);
    const [fileKey,setFileKey] = useState("");
    const [popupVisible, setPopupVisible] = useState(false);

    const token = localStorage.getItem('access-token');
    useEffect(() => {    
        let init = {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({page:Number(page2)}),
            headers: { 
                'Content-Type': 'application/json',
                'access-token': token
            }
        };

        try{
            fetch(datalistURL,init)
            .then(res =>{
                return res.json();
            })
            .then(data => {
                setData(data);
            });
        } catch(err)
        {
            console.log(err);
        }
        
    },[datalistURL,page]);

    useEffect(()=>{setPopupVisible(fileKey ? true:false)},[fileKey])
    //useEffect(()=>{console.log('fileKey Changed:',fileKey);setPopupVisible(true)},[fileKey])

    if(isNaN(page))
        return ("");

    return (
        <div>
        <article>
            <header>
                <h1>기록관리</h1>
            </header>
            <main>
            <div style={{textAlign:"right"}}>
                <button onClick={()=>navigate("/manage/" + (Number(page)-1))} disabled={page==0}>&lt;</button>
                <span style={{padding:"0 10px"}}> <big>#{page}</big> </span>
                <button onClick={()=>navigate("/manage/" + (Number(page)+1))}>&gt;</button>
            </div>
            
            <hr />
            <div  className="overflow-auto">
                <table>
                    <thead>
                        <tr>
                            <th>날짜</th>
                            {
                                data.list && data.list[0]?.name && (<th>업로더</th>)
                            }
                            <th>병원</th>
                            <th>메모</th>
                            <th>파일명</th>
                            <th>Key</th>
                        </tr>
                    </thead>
                    <tbody>
                        { data.list?.length > 0 ? (
                            data.list?.map(v =>(
                                <UploadItem key={v.idx} data={v} 
                                onReqClick={(fkey)=>{ navigate(`/req/${fkey}`)}} 
                                onArchViewOp={(fkey)=>{ navigate(`/archviewop/${fkey}`)}}
                                />
                            ))) : (<tr><td>데이터가 없습니다.</td></tr>)
                        }    
                    </tbody>
                </table>
            </div>
            </main>
            {/* <footer style={{position:"-webkit-sticky", position:"sticky",bottom:"0"}} >
                <ReqProgressComponent fileKey={fileKey}/>
            </footer> */}
            
            
        </article>

        {/* <PopupAnalysis visible={popupVisible} onClose={()=>{setPopupVisible(false)}} fileKey={fileKey} /> */}
        </div>
        
    )
}