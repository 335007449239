import moment from "moment";

import TimeLineChart from "./TimeLineChart";
import { useState, useEffect, useRef } from "react";
import AnalysisUserInput from "./AnalysisUserInputs";


function optimizeData(data, key) {
    if (data.length == 0) return [];

    const optimizedData = [data[0]]; // Start with the first data point

    let last = 0;

    const isDataChanged = (old,cur) => {
        if(isNaN(old) || isNaN(cur))
            return old != cur;

        return Math.abs(cur-old) > Math.abs(cur * 0.003);
    };

    for (let i = 1; i < data.length; i++) {
        //if (data[i][key] !== data[i - 1][key]) {
        if ( isDataChanged(data[i][key],data[last][key])) {
            if(last != i-1)
                optimizedData.push(data[i-1]);
            optimizedData.push(data[i]); // Add only when the value changes
            last = i;
        }
    }

    if(last < data.length-1)
        optimizedData.push(data[data.length-1]);

    // if(opt == true)
    //     optimizedData.push(data[-1])

    return optimizedData;
}

function ExtractTimeRangeKeyCondition(data, key, cond){
    let optData = optimizeData(data,key);

    let oldCondition = 0;
    let retData = [];

    let timeStart = 0;
    let timeEnd = 0;

    optData.map(e => {
        const newCondition = cond(e[key]);

        if(newCondition != oldCondition) { 
            oldCondition = newCondition;

            if(newCondition == true) {
                timeStart = e['datetime'];
            } else {
                timeEnd = e['datetime'];

                const ts = moment(timeStart);
                const te = moment(timeEnd);
                const ds = moment.duration(te.diff(ts)).asSeconds();
                
                retData.push({ 
                    startTime: timeStart,
                    endTime: timeEnd,
                    duration: moment.duration(te.diff(ts)),
                    duration_sec: ds
                })
            }
        }
    })

    if(oldCondition){

        const ts = moment(timeStart);
        const te = moment(optData.at(-1)['datetime']);
        const ds = moment.duration(te.diff(ts)).asSeconds();
        
        retData.push({ 
            startTime: timeStart,
            endTime: optData.at(-1)['datetime'],
            duration: moment.duration(te.diff(ts)),
            duration_sec: ds
        })
    }

    // console.log(key, 'ret:', retData);

    return retData;
}

function ExtractTimeRangeCondition(data, cond){
    let oldCondition = 0;
    let retData = [];

    let timeStart = 0;
    let timeEnd = 0;

    data.map(e => {
        const newCondition = cond(e);

        if(newCondition != oldCondition) { 
            oldCondition = newCondition;

            if(newCondition == true) {
                timeStart = e['datetime'];
            } else {
                timeEnd = e['datetime'];

                const ts = moment(timeStart);
                const te = moment(timeEnd);
                const ds = moment.duration(te.diff(ts)).asSeconds();
                
                retData.push({ 
                    startTime: timeStart,
                    endTime: timeEnd,
                    duration: moment.duration(te.diff(ts)),
                    duration_sec: ds
                })
            }
        }
    })
    
    if(oldCondition){

        const ts = moment(timeStart);
        const te = moment(data.at(-1)['datetime']);
        const ds = moment.duration(te.diff(ts)).asSeconds();
        
        retData.push({ 
            startTime: timeStart,
            endTime: data.at(-1)['datetime'],
            duration: moment.duration(te.diff(ts)),
            duration_sec: ds
        })
    }

    // console.log(key, 'ret:', retData);
    
    return retData;
}

function AddChartDataKeyCondition({logData, chartData, timeRange}, label, key, cond){
    let condData = ExtractTimeRangeKeyCondition(logData, key, cond);

    chartData.labels.push(label);

    let retData = condData.map((e,i)=>{

        const timeData = [e.startTime, e.endTime];

        if(timeRange[0] > moment(timeData[0]))
            timeRange[0] = moment(timeData[0]);
        if(timeRange[1] < moment(timeData[1]))
            timeRange[1] = moment(timeData[1]);

        // dataset 이전 label이 있고, 이전 label의 데이터개수가 현재 label의 데이터 개수보다 많으면
        if(chartData.datasets.length <= i) {

            // data를 label 개수만큼 만들고 null로 채운다.
            let data = new Array(chartData.labels.length).fill(null);

            // data의 마지막 요소가 현재 label이므로
            data[data.length-1] = timeData;

            chartData.datasets.push({
                data, 
                backgroundColor:"rgba(75, 192, 192, 0.2)",
                borderColor:"rgb(75, 192, 192)",
                borderWidth:1,
                borderSkipped:false,
                borderRadius:4,
                //barPercentage:0.9,
                //categoryPercentage:0.1,
                //maxBarThickness:20,
            });

        // 그렇지 않으면
        } else {
            while(chartData.datasets[i].data.length < chartData.labels.length - 1)
                chartData.datasets[i].data.push(null);

            chartData.datasets[i].data.push(timeData);
        }

        return timeData;
    });

    return retData;
}


function AddChartDataCondition({logData, chartData, timeRange}, label, cond){
    let condData = ExtractTimeRangeCondition(logData, cond);

    chartData.labels.push(label);

    let retData = condData.map((e,i)=>{

        const timeData = [e.startTime, e.endTime];

        if(timeRange[0] > moment(timeData[0]))
            timeRange[0] = moment(timeData[0]);
        if(timeRange[1] < moment(timeData[1]))
            timeRange[1] = moment(timeData[1]);

        // dataset 이전 label이 있고, 이전 label의 데이터개수가 현재 label의 데이터 개수보다 많으면
        if(chartData.datasets.length <= i) {

            // data를 label 개수만큼 만들고 null로 채운다.
            let data = new Array(chartData.labels.length).fill(null);

            // data의 마지막 요소가 현재 label이므로
            data[data.length-1] = timeData;

            chartData.datasets.push({
                data, 
                backgroundColor:"rgba(75, 192, 192, 0.2)",
                borderColor:"rgb(75, 192, 192)",
                borderWidth:1,
                borderSkipped:false,
                borderRadius:4,
                //barPercentage:0.9,
                //categoryPercentage:0.1,
                //maxBarThickness:20,
            });

        // 그렇지 않으면
        } else {
            while(chartData.datasets[i].data.length < chartData.labels.length - 1)
                chartData.datasets[i].data.push(null);

            chartData.datasets[i].data.push(timeData);
        }

        return timeData;
    });

    return retData;
}


export default function LogTimeLine({logData, setLaserTime=(t)=>{}, setOpTime=(t)=>{}}){
    let [transThreshold, setTransThreshold] = useState(400);
    let [tooltrThreshold, setTooltrThreshold] = useState(5);
    let [cData, setChartData] = useState(null);
    let [tRange, setTimeRange] = useState([moment('9999-12-31'),moment('1970-01-01')]);
    //let [opTimeMin, setOpTime] = useState(0);
    //let [laserTimeMin, setLaserTime] = useState(0);

    const updateChartData = ()=>{
        if(!logData || !logData.length || logData.length === 0) return;

        let timeRange = [moment('2100-12-31'),moment('2000-01-01')];
    
        let chartData = {
            labels: [],
            datasets: []
        };
    
        const inp={
            logData,
            chartData,
            timeRange,
        }
    
        const opTime = AddChartDataKeyCondition(inp, "OPMode (OPXXX)","ControlState", e=>e.startsWith("OP"));
        AddChartDataKeyCondition(inp, "Laser Equipped", "SDI_LaserEq", (e)=>(e===1));
        AddChartDataKeyCondition(inp, "Basket Equipped", "SDI_BasketEq", (e)=>(e===1));
        AddChartDataKeyCondition(inp, "Sheath Position", "SMT_TranActualPosition", (e)=>(e > transThreshold));
        AddChartDataKeyCondition(inp, "Laser Translation", "SMT_ToolTrActualPosition", (e)=>(e > tooltrThreshold));
    
        const laserTime = AddChartDataCondition(inp,"Total Cond", (e)=>(
            e['ControlState'].startsWith("OP") 
            && e['SDI_LaserEq'] == 1
            && e['SMT_TranActualPosition'] > transThreshold
            && e['SMT_ToolTrActualPosition'] > tooltrThreshold
        ));
    
        // console.log('optime',opTime);
        // console.log('laser time', laserTime);
    
        const getTimeSum = (arr)=>(arr.reduce((acc,curr) => (
            acc + moment.duration(moment(curr[1]).diff(moment(curr[0]))).asMinutes()
        ),0))
        
        // console.log('time range: ',timeRange);
        setTimeRange(timeRange);
        setChartData(chartData);
        setOpTime(getTimeSum(opTime));
        setLaserTime(getTimeSum(laserTime));
    }

    useEffect(updateChartData,[logData]);


    //laserTime.reduce((acc,curr) =>( acc + moment.duration(moment(curr[1]).diff(moment(curr[0]))).asSeconds(),0))

    
    return cData && (
    <div>
        <details open>
            <summary role="button" class="secondary">Timeline Chart</summary>
            <TimeLineChart chartData={cData} timeRange={tRange}/>
                
            <details>
                <summary role="button" class="outline secondary">Edit Parameters</summary>
                <label>
                    Sheath length Threshold: <strong>{transThreshold}</strong>
                    <input type="range" max="600" value={transThreshold} onChange={e=>{setTransThreshold(e.target.value)}}/>
                </label>
                <label>
                    Tool Translation Threshold: <strong>{tooltrThreshold}</strong>
                    <input type="range" max="200" value={tooltrThreshold*10} onChange={e=>{setTooltrThreshold(e.target.value/10)}} />
                </label>
                <button onClick={updateChartData}>Apply</button>
            </details>
            
        </details>
        <hr/>
        
        {/* <AnalysisUserInput data={{opTimeMin,laserTimeMin}}/> */}
    </div>
    );
}