import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import zoomPlugin from 'chartjs-plugin-zoom';  // Import the zoom plugin


export default function TimeLineChart( {chartData, timeRange=[0,1]} ) {
    const options = {
        //responsive: false,
        maintainAspectRatio:false,
        responsive: true,
        indexAxis: 'y',
        plugins: {
            legend: {
                display:false,
                position: 'top',
            },
            title: {
                display: true,
                text: 'Laser Analysis'
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'x',  // Enable panning on the x-axis
                },
                zoom: {
                    wheel: {
                        enabled: true,  // Enable zooming with the mouse wheel
                    },
                    pinch: {
                        enabled: true  // Enable zooming with pinch gestures
                    },
                    mode: 'x',  // Enable zooming on the x-axis
                }
            }
        },
        scales: {
            y: {
                stacked: true
            },
            x: {
                type: 'time',
                time: {
                // Luxon format string
                    tooltipFormat: 'yyyy-MM-dd HH:mm:ss.SSS' ,
                    //unit:'second'
                },
                min: new Date(timeRange[0]),
                max: new Date(timeRange[1])
            }
        }
    };


    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            //border: "1px solid red",
            alignItems: "center",
            height: "35vh",
            minHeight:"400px",
            padding: "0 30px",
        }}>
        <Bar data={chartData} options={options} plugins={[zoomPlugin]} />
      </div>
    );
}

