import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';

function formatFileSize(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Bytes';
    
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    const size = parseFloat((bytes / Math.pow(1024, i)).toFixed(2)); // 소수점 2자리까지 표시
    
    return `${size} ${sizes[i]}`;
}

function getFileType(filename)
{
    if(!filename)
        return (<span data-tooltip={filename}>Unknown</span>);
    
    if(filename.includes("RECORD_"))
        return "장치로그";
    else if(filename.includes("OPERATION_"))
        return "수술로그"
    else if(filename.includes("easyuretero.db"))
        return "데이터베이스"
    else
        return (<span data-tooltip={filename}>Unknown</span>);

}

function ReqItem({file, onDownloadClick})
{
    return (
        <tr> 
            <td><span>{file.date}</span></td>
            <td>{getFileType(file.filename)}</td>
            <td>{formatFileSize(file.size)}</td>
            <td><button onClick={()=>{onDownloadClick(null)}}>분석</button></td>
        </tr>
    )    
}

// 파일명에서 날짜를 추출하는 함수
function extractDateFromFilename(filename) {
    // 날짜 형식을 찾는 정규식 (YYYYMMDD_HHMMSS)
    const datePattern = /\d{4}\d{2}\d{2}_\d{2}\d{2}\d{2}/;

    const match = filename.match(datePattern);

    // 날짜 형식이 있으면 추출, 없으면 null
    return match ? match[0] : null;
}


// 파일명 배열을 필터링하고 날짜 추출하는 함수
function filterFilenamesWithDate(files) {
    return files
        .map(v => {
        const date = moment(extractDateFromFilename(v.filename),"YYYYMMDD_HHmmss").format("YYYY-MM-DD HH:mm");
        return ((date !== null) && (v.size > 0)) ? { filename: v.filename, date, size: v.size, mapping : v.mapping } : null; // 날짜가 있으면 {파일명, 날짜} 객체로 반환
        })
        .filter(item => item !== null); // 날짜가 있는 파일만 필터링
}

export default function ReqProgressComponent(/*props*/) {
    const [progress, setProgress] = useState(0);
    const [message, setMessage] = useState(''); 
    const [fileList, setFileList] = useState(null);  // 다운로드 받은 파일 리스트
    const [fileListResult, setFileListResult] = useState(null); // 파일 리스트 보여주기
    const [sortkey, setSortKey] = useState('date');
    const [sortDesc, setSortDesc] = useState(1);

    const {fkey} = useParams();
    const navigate = useNavigate();

    const getLogArchive = ()=>{
        if(!fkey)
            return;

        const config = { headers: { 'access-token': localStorage.getItem('access-token') } }

        axios.post(`/api/req/${fkey}`, {}, config ) // 파일을 Blob으로 받음
        .then((response) => {
            const res = response.data;
            setProgress(100);
            setMessage(res.message);
            setFileList(res.data);

            // console.log('data', res.data);
        })
        .catch((error) => {
          console.error('File download error:', error);
        });
    }

    useEffect(getLogArchive, [fkey]);

    const updateData = ()=>{
        if(!fileList)
            return;

        const res = filterFilenamesWithDate(fileList)
                .filter(v => getFileType(v.filename)==="장치로그"); // 장치로그만 필터링
        
        setFileListResult(res);
    }
    useEffect(updateData,[fileList]);

    // useEffect(()=>{setLogAnalysisVisible(logContent !== null)},[logContent])

    const toggleSortDesc = ()=>{setSortDesc(sortDesc * -1)}
    const sortButtonClicked = (key)=>{
        if(sortkey === key){
            toggleSortDesc()
        }else{
            setSortKey(key);
            setSortDesc(1);
        }
    }
    const dispSortKey = (key)=> (sortkey === key && (sortDesc > 0 ? "▲":"▼"))

    return (
        <div>
            <progress value={progress} max="100"></progress>
            <p>{progress}% - {message}</p>
            {fileListResult && (
                <div style={{overflowY:"auto"}}>
                    <table>
                        <thead>
                        <tr>
                            <th onClick={()=>{sortButtonClicked('date')}}>Date {dispSortKey('date')}</th>
                            <th onClick={()=>{sortButtonClicked('filename')}}>File Type {dispSortKey('filename')}</th>
                            <th onClick={()=>{sortButtonClicked('size')}}>File Size {dispSortKey('size')}</th>
                            <th>Analysis</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            fileListResult
                                .sort((a,b)=>{ return a[sortkey] > b[sortkey] ? (1 * sortDesc) : (a[sortkey] < b[sortkey] ? (-1 * sortDesc) : (0)) })
                                .map(v=>(
                                <ReqItem 
                                    file={v} 
                                    onDownloadClick={(trange)=>{
                                        const timeRangeParam = trange && (`&stime=${trange[0]}&etime=${trange[1]}`) || '';
                                        navigate(`/analysis_k?fkey=${encodeURIComponent(fkey)}&fname=${encodeURIComponent(v.filename)}${timeRangeParam}`);
                                        //navigate(`/analysis_k?fkey=${encodeURIComponent(fkey)}&fname=${encodeURIComponent(v.filename)}`);
                                    }} 
                                />))
                        }
                        </tbody>
                    </table>
                </div>
            )}
            {fileListResult && fileListResult.length == 0 && (
                <div> <p>표시할 수 있는 데이터가 없습니다.</p>
                </div>
            )}

        </div>
    );
};