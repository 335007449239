import axios from "axios";
import { useState } from "react";

const API_URL = process.env.REACT_APP_BACKEND_URL;
const presignedURL = API_URL + '/api/generate-presigned-download-url';

export default function UploadItem({ data, onReqClick, onArchViewOp }){
    let [progress, setProgress] = useState("");

    
async function download(fileKey) {
    //console.log('download: ' + fileKey);
    
    try{
        const header = {
            headers: { 
                'access-token': localStorage.getItem('access-token')
            }
        }

        let filename = "download";

        axios
          .post(presignedURL, { fileKey }, header)
          .then(({ data }) => {
            //console.log(data);

            filename = data.fileName;

            return axios.get(data.url, {
              headers: { "Content-Type": "application/octet-stream" },
              responseType: "blob",

              onDownloadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );

                setProgress(`(${percentCompleted}%)`);
              },
            });
          })
          .then((res) => {
            const a = document.createElement("a");
            const url = window.URL.createObjectURL(res.data);
            a.href = url;
            a.download = filename;
            a.click();
          })
          .then(() => {
            setProgress("");
          })
          .catch((err) => {
            console.log(err);
          });

    }catch(err){
        console.log(err);
    }
};


    function convTime(v){
        if(v == null) return "";

        let t = new Date();
        t.setTime(v);

        return `${t.getFullYear()}-${t.getMonth()+1}-${t.getDate()} ${t.getHours()}:${t.getMinutes()}:${t.getSeconds()}`
    }

    let ellipseText = (text,length) =>{
        if(text == null)
            return;

        if(text.length > length)
            return text.substr(0,length).concat("...");
        return text;
    };

    return (
        <tr>
            <td>{convTime(data.time_js)}</td>
            {data.name && (<td>{data.name}</td>)}
            <td>{data.hospitalName}</td>
            <td>{data.desc}</td>
            <td><small data-tooltip={data.fileName}>{ellipseText(data.fileName,20)}</small></td>
            <td><button onClick={()=>{download(data.fileKey)}}>다운로드{progress}</button></td>
            <td><button onClick={()=>{onReqClick(data.fileKey)}}>내용확인</button></td>
            <td><button onClick={()=>{onArchViewOp(data.fileKey)}}>수술기록</button></td>
        </tr>
        
    )
}


